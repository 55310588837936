<template>
<!--    <div class="navigation__button-group">-->
        <button class="button button_view_icon button_theme_base mtb__-4" @click="displaySectionInfo('NotificationsProps')">
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit_lg" /></span> </span>
        </button>
<!--    </div>        -->
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: 'NotificationsEventsList_navigation_menu',
    props: [],
    components: {},
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
        ]),
    },
    methods: {
        ...mapActions([
            "setSectionInfo",
            "setSectionWidget",
        ]),
        ...mapMutations([
            "hideMainList",
        ]),
        displaySectionInfo(component){
            this.hideMainList()
            this.setSectionInfo({
                component: component,
                props: {
                    time: Date.now()
                }
            })
        },
        displaySectionWidget(component){
            this.setSectionWidget({
                component: component,
                props: {
                    time: Date.now()
                }
            })
        },
    },
    created() {
    },
    updated: function() {
        // console.log(`NotificationsEventsList_navigation updated`);
    },
    activated() {
    },
    deactivated() {
    },
    mounted(){
    },
    beforeDestroy() {
    },
}
</script>
