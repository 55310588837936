<template>
    <div class="list__button-group">
        <button class="button button_view_icon button_theme_base mtb__-4" >
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__edit"/></span> </span>
        </button>
        <button class="button button_view_icon button_theme_base is_danger mtb__-4">
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__delete"/></span> </span>
        </button>
        <ButtonIcon_dual_status
            :button-class="'mtb__-4'"
            :icon="isActive ? 'common__notice' : 'common__notice_disabled'"
            :icons="['common__notice', 'common__notice_disabled']"
            :value="isActive"
        />
    </div>
</template>
<script>
    import {mapActions,mapGetters} from "vuex";

    export default {
        name: 'NotificationsEventsList_event_menu',
        props: [
            'event',
        ],
        components: {},
        data() {
            return {
                isActive: false,
            }
        },
        computed: {
            ...mapGetters([
            ]),
        },
        methods: {
            ...mapActions([
            ]),
        },
        updated: function() {
            // console.log(`NotificationsEventsList_event_menu ${this.event} updated`);
        },
    }
</script>