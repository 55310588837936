<template>
    <section class="section__body">

        <!--- Navigation --->
        <NotificationsEventsList_navigation />

        <!--- Container --->
        <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
            <SectionLoading :inprogress="inprogress" />

            <section class="section section_type_loader loader "
                     :class="{'loader_status_active': no_data }"
            >
                <span class="loader__text">{{ $t('text.No data') }}</span>
            </section>

            <NotificationsEventsList_chronology v-show="!inprogress" :unit-id="events_unitId" :date="events_date" />
        </section>
    </section>
</template>

<script>
    import debounce from "lodash/debounce"
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import NotificationsEventsList_navigation from "./NotificationsEventsList_navigation.vue";
    import NotificationsEventsList_chronology from "./NotificationsEventsList_chronology.lite.vue";

    export default {
        name: "NotificationsEventsList",
        components: {
            NotificationsEventsList_chronology,
            NotificationsEventsList_navigation,
        },
        data() {
            return {
                pageActive: false,
                debouncedFetchData: null,
                interval: null,
            }
        },
        computed: {
            ...mapGetters([
                "getEventsFilter",
                "getEventsFilteredByUnitDate",
                "getEventsIndexFiltered",
                "getNotificationsEventsFilter",
                "getNotificationsEventsFilteredByUnitDate",
                "getNotificationsEventsIndexFiltered",
            ]),
            inprogress(){
                return (this.events_date>'')
                    && !this.getEventsIndexFiltered
                    && !this.getNotificationsEventsIndexFiltered
            },
            no_data() {
                return (this.events_date>'')
                    && !this.inprogress
                    && !this.getEventsFilteredByUnitDate.length
                    && !this.getNotificationsEventsFilteredByUnitDate.length
            },
            events_unitId(){
                return this.getNotificationsEventsFilter.unitId || 0
            },
            events_date(){
                return this.getNotificationsEventsFilter.date || ''
            },
        },
        watch: {
            getNotificationsEventsFilter: {
                handler: function (newVl, oldVl) {
                    if(newVl.unitId !== oldVl.unitId || newVl.date !== oldVl.date)
                    {
                        console.log('getNotificationsEventsFilter', {...newVl}, {...oldVl})
                        this.debouncedFetchData();
                    }
                },
                deep: true
            },
        },
        methods: {
            ...mapActions([
                "fetchLastEvents",
                "fetchEvents4Date",
                "fetchLastNotificationsEvents",
                "fetchNotificationsEvents4Date",

                "setNotificationEventShow",
            ]),
            ...mapMutations([
                "setEventsFilter",
                "setNotificationsEventsFilter",
            ]),
            fetchData(){
                //console.log('fetchData', this.getNotificationsEventsFilter, this.getEventsIndexFiltered, this.getNotificationsEventsIndexFiltered)
                if(this.getEventsIndexFiltered || this.getNotificationsEventsIndexFiltered)//(this.track_unitId>0 && this.track_date>'')
                    return false
                let filter = this.getNotificationsEventsFilter//{ unitId: '', date: '', type: '', name: '' }
                this.fetchEvents4Date(filter)
                this.fetchNotificationsEvents4Date(filter)
            },
        },
        created() {
            //console.log('NotificationsEventsList created')
            this.debouncedFetchData = debounce(this.fetchData, 500)
            // this.setEventsFilter({
            //     unitId: '',
            //     date: new Date().toISOString().split('T')[0],
            //     type: '',
            //     name: '',
            // })
        },
        mounted() {
            // console.log('NotificationsEventsList mounted')
        },
        updated() {
            //console.log('NotificationsEventsList updated')
        },
        activated() {
            //console.log('NotificationsEventsList activated')
            this.pageActive = true
            let filter = this.getNotificationsEventsFilter//{ unitId: '', date: '', type: '', name: '' }
            this.setEventsFilter(filter) //!!!
            this.setNotificationsEventsFilter(filter)

            // this.fetchLastEvents('50 hours')
            // this.fetchLastNotificationsEvents('50 hours')

            //this.fetchLastEvents('30 days')
            //this.fetchLastNotificationsEvents('30 days')

            this.interval = setInterval(() => {
                this.fetchLastEvents('30 minutes')//'1 hour'
                this.fetchLastNotificationsEvents('30 minutes')//'1 hour'
            }, 60*1000)
        },
        deactivated() {
            //console.log('NotificationsEventsList deactivated')
            this.pageActive = false
            this.setNotificationEventShow(null)
            if(this.interval) {
                clearInterval(this.interval)
                this.interval = null
            }
        },
    }
</script>

