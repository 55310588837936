var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DynamicScroller',{attrs:{"items":_vm.getNotificationsEventsByDatesWithSize,"min-item-size":56,"buffer":100,"page-mode":""},scopedSlots:_vm._u([{key:"before",fn:function(){return undefined},proxy:true},{key:"default",fn:function(ref){
var item = ref.item;
var active = ref.active;
return [_c('DynamicScrollerItem',{staticClass:"list mb__12 list_view_insular",attrs:{"item":item,"active":active,"size-dependencies":[
                    _vm.getEventsFilter,
                    _vm.getEventsFiltered,
                    _vm.getNotificationsEventsFilter,
                    _vm.getNotificationsEventsFiltered,
                    _vm.getNotificationEventShow,
                    item.events ],"tag":"ul"}},_vm._l((item.events),function(event,i){return _c('NotificationsEventsList_event',{key:'NotificationsEventsList_date-'+item.date.date+'-type-'+event.type+'-event-'+event.id+i,attrs:{"event":event}})}),1)]}},{key:"after",fn:function(){return undefined},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }