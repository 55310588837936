<template>
    <div class="list__content">
        <table class="list__table table">
            <tr>
                <th>{{ $t('text.Text') }}:</th>
                <td>{{title}}</td>
            </tr>
            <tr>
                <th>{{ $t('text.Time') }}:</th>
                <td>{{time}}</td>
            </tr>
            <tr>
                <th>{{ $t('text.Address') }}:</th>
                <td>{{address}}</td>
            </tr>
            <tr>
                <th>{{ $t('text.Unit') }}: </th>
                <td>{{unit_name}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
    import {mapActions,mapGetters} from "vuex";

    export default {
        name: "NotificationsEventsList_event_content",
        props: [
            'event',
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsByIds"
            ]),
            title(){
                return this.event.message || this.event.text
            },
            address(){
                return this.event.address>''? this.event.address : this.event.latlng
            },
            time(){
                return this.event.time.formated['time-s']
            },
            coords(){
                return this.event.latlng
            },
            unit(){
                return this.unitsByIds[this.event.unit_id]
            },
            unit_name(){
                return this.unit ? this.unit.name : ''
            }
        },
        methods: {
            ...mapActions([
            ]),
        },
        updated: function() {
            // console.log(`NotificationsEventsList_event_content ${this.event} updated`);
        },
    }
</script>

<style scoped>

</style>