<template>
    <DynamicScroller
            :items="getNotificationsEventsByDatesWithSize"
            :min-item-size="56"
            :buffer="100"
            page-mode
    >
        <template #before>
            <!-- before -->
        </template>

        <template v-slot="{ item, active }">
            <DynamicScrollerItem
                    :item="item"
                    :active="active"
                    :size-dependencies="[
                        getEventsFilter,
                        getEventsFiltered,
                        getNotificationsEventsFilter,
                        getNotificationsEventsFiltered,
                        getNotificationEventShow,
                        item.events,
                    ]"
                    tag="ul"
                    class="list mb__12 list_view_insular"
            >
                <NotificationsEventsList_event
                        v-for="(event,i) in item.events"
                        :key="'NotificationsEventsList_date-'+item.date.date+'-type-'+event.type+'-event-'+event.id+i"
                        :event="event"
                />
            </DynamicScrollerItem>
        </template>

        <template #after>
            <!--  after -->
        </template>
    </DynamicScroller>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    import NotificationsEventsList_event from "./NotificationsEventsList_event.vue";

    export default {
        name: "NotificationsEventsList_chronology",
        props: [
            'unitId',
            'date',
        ],
        components: {
            NotificationsEventsList_event,
        },
        data() {
            return {
                pageActive: false,
                interval: null,
            }
        },
        computed: {
            ...mapGetters([
                "getEvents",
                "getEventsByIds",
                "getEventsFiltered",
                "getEventsFilteredByUnitDate",
                "getEventsFilter",

                "getNotificationsEvents",
                "getNotificationsEventsByIds",
                "getNotificationsEventsFiltered",
                "getNotificationsEventsFilteredByUnitDate",
                "getNotificationsEventsFilter",

                "getNotificationEventShow",

                "getAppUserList30Days",
            ]),
            dates(){
                return this.getAppUserList30Days
            },
            getNotificationsEventsByDatesWithSize(){
                //console.log('getNotificationsEventsByDatesWithSize')
                let date = this.dates.find(date => { return date.date == this.getNotificationsEventsFilter.date })
                if(!date) date = {date: '', short: ''}
                let events = this.getEventsFilteredByUnitDate
                    // .filter(e => { return e.time.date == date.date})
                    .map(e => {
                        let message = e.text || ''
                        if(e.tmp_msg && e.tmp_msg.msg){
                            message = e.tmp_msg.msg.replace(/%TEXT%/i, this.$t('message.'+e.tmp_msg.TEXT))
                        }
                        return {...e, message}
                    })
                let NEvents = this.getNotificationsEventsFilteredByUnitDate
                    // .filter(ne => { return ne.time.date == date.date})
                    .map(e => {
                        let message = e.text || ''
                        if(e.tmp_msg && e.tmp_msg.msg){
                            message = e.tmp_msg.msg.replace(/%TEXT%/i, this.$t('message.'+e.tmp_msg.TEXT))
                        }
                        return {...e, message}
                    })
                if(NEvents.length) events.push(...NEvents)

                //let collator = new Intl.Collator();
                events.sort(function (a, b) {
                    if(a.time.utc == b.time.utc){
                        return a.id > b.id? 1 : -1;
                    }
                    return a.time.utc > b.time.utc? -1 : 1;
                })

                // let size = 40*events.length + 44+12
                return [{
                    id: date.date,//!!!
                    date,
                    events,
                    // size,
                }]
            },
        },
        methods: {
            ...mapActions([
            ]),
        },
        created() {
            //console.log('NotificationsEventsList created')
        },
        mounted() {
            //console.log('NotificationsEventsList mounted')
        },
        updated() {
            //console.log('NotificationsEventsList updated')
        },
    }
</script>

