<template>
    <li class="list__item item_view_extended- item_has-content"
        :class="{'item_active': isActive}"
        v-show="isFilteredEvent"
        @mouseover="showMenu = true" @mouseleave="showMenu = false"
        @click.stop="onClick()"
    >
        <span class="list__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" :name="'notifications__'+imageName" /></span> </span>
        <h4 class="list__title">{{title}}</h4>
        <NotificationsEventsList_event_content :event="event" v-show="isActive" />
        <NotificationsEventsList_event_menu :event="event" v-if="false && showMenu" />
    </li>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";
    import NotificationsEventsList_event_content from "./NotificationsEventsList_event_content.vue";
    import NotificationsEventsList_event_menu from "./NotificationsEventsList_event_menu.vue";

    export default {
        name: 'NotificationsEventsList_event',
        props: [
            'event'
        ],
        components: {
            NotificationsEventsList_event_content,
            NotificationsEventsList_event_menu
        },
        data() {
            return {
                showContent: false,
                showMenu: false,
            }
        },
        computed: {
            ...mapGetters([
                "getEventsByIds",
                "getEventsFilter",
                "getNotificationsEventsByIds",
                "getNotificationsEventsFilter",

                "getNotificationEventActive",
                "getEventActive",
            ]),
            title(){
                return this.event.message || this.event.text
            },
            address(){
                return this.event.address;
            },
            time(){
                return this.event.time;
            },
            coords(){
                return this.event.latlng
            },
            imageName(){
                if(this.event.type === 'notification-event') {
                    return this.event.images.length ? '' + this.event.images[0] : ''
                }else
                if(this.event.type === 'event') {
                    return this.event['sub-type']>'' ? '' + this.event['sub-type'].replaceAll(' ', '-') : ''
                }
                return ''
            },
            isFilteredEvent(){
                let isFiltered = true
                let filter = this.getNotificationsEventsFilter
                let name = filter.name ? filter.name.toLocaleLowerCase() : ''
                if(filter.name > '') {
                    isFiltered &= this.event.message.toLocaleLowerCase().includes(name)
                }
                if(filter.type > '') {
                    isFiltered &= (this.event['sub-type'] == filter.type)
                }
                return isFiltered
            },
            isActive(){
                if(this.event.type === 'notification-event') {
                    return this.getNotificationEventActive === this.event.id
                }else
                if(this.event.type === 'event') {
                    return this.getEventActive === this.event.id
                }
                return false
            },
        },
        methods: {
            ...mapActions([
                "setNotificationEventShow",
                "setMapCenter",
                "setSectionInfo",
                "setEventActive",
                "setNotificationEventActive",
            ]),
            onClick(){
                if(this.event.type === 'notification-event') {
                    this.setNotificationEventActive(this.event.id)
                    this.setEventActive(null)
                }else
                if(this.event.type === 'event') {
                    this.setEventActive(this.event.id)
                    this.setNotificationEventActive(null)
                }
                setTimeout(() => this.setNotificationEventShow({...this.event, image: this.imageName}), 100)
            },
        },
        updated() {
            // console.log(`NotificationsEventsList_event ${this.eventId} updated`);
        },
    }
</script>