<template>
    <!--- Navigation --->
    <nav class="section__navigation plr__12 pt__12">
        <div class="horizontal-line mb__12">
            <SegmentControl_slider
                :segment-control-class="'mr__8'"
                :field_id="'date'"
                :field_name="'label'"
                :items="dates"
                v-model="events_date"
            />
            <NotificationsEventsList_navigation_menu />
        </div>

        <div class="horizontal-line section__navigation-mobile mb__12">
            <FieldGroup_kwrapper_dropdownlist
                :field-group_class="'field-group_view_compact mr__12'"
                :id="'track-unit'"
                :label="$t('NotifEvents.Unit')"
                :info="''"
                :items="unitsActivated"
                :error="errors.unitId"
                v-model="events_unitId"
            />
            <FieldGroup_flatpickr
                :field-group_class="'field-group_view_calendar field-group_view_compact'"
                :id="'track-date'"
                :label="$t('NotifEvents.Day')"
                :input_icon="'input__date'"
                :max-date="getDateToday"
                :error="errors.date"
                v-model="events_date"
            />
        </div>

        <div class="horizontal-line">
            <div class="filter-search filter-search_has-filter mb__12"
                 :class="{'filter-search_active': FilterSearchValue>'','filter-search_focus': FilterSearchFocusin}"
            >
                <button class="filter-search__filter button button_view_icon button_theme_primary button_dropdown-menu"
                        @click="switchShowFilterDropdownMenu"
                >
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__slider" /></span> </span>
                </button>
                <ul class="filter-search__choice">
                    <li class="filter-search__choice-item"
                        v-show="getNotificationsEventsFilter.type"
                        @click="onClearFilter"
                    >
                        {{FilterSearchTypeText}}
                    </li>
                </ul>
                <input class="filter-search__input" type="text" :placeholder="$t('text.Search')" v-model="FilterSearchValue" @focus="FilterSearchFocusin=true" @blur="FilterSearchFocusin=false">
                <button class="filter-search__close button button_view_icon button_theme_primary" @click="FilterSearchValue=''">
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close_sm" /></span> </span>
                </button>
                <div class="filter-search__dropdown-menu dropdown-menu"
                     :class="{'dropdown-menu_open': showFilterDropdownMenu}"
                >
                    <div class="dropdown-menu__wrap">
                        <ul class="dropdown-menu__list">

                            <li class="dropdown-menu__item item item_has-checkbox" data-type="type"
                                v-for="type in FilterSearchLists_types"
                                :key="type.id"
                                :class="{'item_active': getNotificationsEventsFilter.type === type.id || getEventsFilter.type === type.id}"
                                @click.stop="onSetFilter(type)"
                            >
                                <span class="dropdown-menu__icon icon icon_type_checkbox"> <span class="icon__symbol"><SvgIcon class="icon" name="common__checkbox" /></span> </span>
                                <span class="dropdown-menu__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" :name="type.icon" /></span> </span>
                                <span class="dropdown-menu__item-name">{{$t(type.translate)}} </span>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </nav>
</template>

<script>
    import {mapActions, mapGetters, mapMutations,} from "vuex";
    import NotificationsEventsList_navigation_menu from "@/components/notifications_events_history/NotificationsEventsList_navigation_menu.vue";
    import {dateFormat} from "@/filters/dateFormat";

    export default {
        name: "NotificationsEventsList_navigation",
        props: [],
        components: {NotificationsEventsList_navigation_menu},
        data() {
            return {
                FilterSearchFocusin: false,
                errors: {},
            }
        },
        computed: {
            ...mapGetters([
                "getEventsFilter",
                "getNotificationsEventsFilter",
                "getAppUserList30Days",
                "getFilterSearchLists",
                "showFilterDropdownMenu",
                "unitsActivated",
                "getDateToday",
                "getDateFormatAll",
            ]),
            dates(){
                let dates = this.getAppUserList30Days.map(dt => {
                    return {...dt, label: dateFormat(dt.date, this.getDateFormatAll.short || 'MMM D', this.$root.$i18n.locale)}
                })
                dates[0].label = this.$t('text.Today')
                dates[1].label = this.$t('text.Yest_')
                return dates
            },
            events_unitId: {
                get () {
                    return this.getNotificationsEventsFilter.unitId || 0
                },
                set (unitId) {
                    //this.setShowUnitEvents({...this.getShowUnitEvents, unitId})
                    this.setEventsFilter({...this.getEventsFilter, unitId})
                    this.setNotificationsEventsFilter({...this.getNotificationsEventsFilter, unitId})
                }
            },
            events_date: {
                get () {
                    return this.getNotificationsEventsFilter.date || ''
                },
                set (date) {
                    // this.setShowUnitEvents({...this.getShowUnitEvents, date})
                    this.setEventsFilter({...this.getEventsFilter, date})
                    this.setNotificationsEventsFilter({...this.getNotificationsEventsFilter, date})
                }
            },
            FilterSearchLists_types(){
                let types = []
                types.push(...this.getFilterSearchLists.Events.types)
                types.push(...this.getFilterSearchLists.NotificationsEvents.types)
                return types
            },
            FilterSearchTypeText() {
                let type =
                this.getFilterSearchLists.NotificationsEvents.types.find(s => {
                    return s.id === this.getNotificationsEventsFilter.type
                })
                ||
                this.getFilterSearchLists.Events.types.find(s => {
                    return s.id === this.getEventsFilter.type
                })
                return (type)? this.$t(type.translate) : ''
            },
            FilterSearchValue: {
                get(){
                    // console.log('FilterSearchValue.get', this.getNotificationsEventsFilter.name)
                    let name = null
                    name = this.getEventsFilter.name
                    //name = this.getNotificationsEventsFilter.name
                    return name
                },
                set(value){
                    let filter = null
                    filter = this.getEventsFilter
                    filter.name = value
                    this.setEventsFilter(filter)
                    filter = this.getNotificationsEventsFilter
                    filter.name = value
                    this.setNotificationsEventsFilter(filter)
                },
            },
        },
        methods: {
            ...mapActions([
                // "setShowUnitEvents",
            ]),
            ...mapMutations([
                "setEventsFilter",
                "setNotificationsEventsFilter",
                "switchShowFilterDropdownMenu",
                "setShowFilterDropdownMenu",
            ]),
            setEventsFilterParam(param, value){
                let filter = this.getEventsFilter
                filter[param] = value
                this.setEventsFilter(filter)
            },
            setNotificationsEventsFilterParam(param, value){
                let filter = this.getNotificationsEventsFilter
                filter[param] = value
                this.setNotificationsEventsFilter(filter)
            },
            onClearFilter(){
                this.setEventsFilterParam('type', '')
                this.setNotificationsEventsFilterParam('type','')
            },
            onSetFilter(type){
                // if(type.type == 'notification-event') {
                //     this.setNotificationsEventsFilterParam('type', type.id)
                //     this.setEventsFilterParam('type', '-')
                // }else{
                //     this.setEventsFilterParam('type', type.id)
                //     this.setNotificationsEventsFilterParam('type', '-')
                // }
                // notification-event & event types are not equal
                this.setNotificationsEventsFilterParam('type', type.id)
                this.setEventsFilterParam('type', type.id)
            },
            // resetNotificationsEventsFilter(){
            //     this.setEventsFilter({type: '', name: '',})
            //     this.setNotificationsEventsFilter({type: '', name: '',})
            // },
        },
        created() {
            // console.log(`NotificationsEventsList_navigation created`);
        },
        updated() {
            // console.log(`NotificationsEventsList_navigation updated`);
        },
        activated() {
            // console.log(`NotificationsEventsList_navigation activated`);
        },
        deactivated() {
            // console.log(`NotificationsEventsList_navigation deactivated`);
        },
        mounted(){
            // console.log(`NotificationsEventsList_navigation mounted`);
            this.events_date = new Date().toISOString().split('T')[0]
        },
        beforeDestroy() {
        },
    }
</script>

<style scoped>

</style>